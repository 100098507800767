// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import Home from './pages/Home';
import Landing from './pages/Landing';
import Profile from './pages/Profile';
import { UserProvider } from './context/UserProvider';
import PrivateRoute from './components/PrivateRoute';
import ViewProfile from './pages/ViewProfile';

function getLibrary(provider) {
  return new Web3Provider(provider);
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <UserProvider>
        <Router>
          <div className="App">
            <Routes>
              <Route path="/landing" element={<Landing />} />
              <Route path="/" element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              } />
              <Route path="/profile" element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              } />
              <Route path="/viewprofile/:userId" element={
                <PrivateRoute>
                  <ViewProfile />
                </PrivateRoute>
              } />
              <Route path="*" element={<Navigate to="/landing" />} />
            </Routes>
          </div>
        </Router>
      </UserProvider>
    </Web3ReactProvider>
  );
}

export default App;