import React, { useContext, useState, useEffect, useRef } from 'react';
import { Box, Typography, Card, CardMedia, CardContent, IconButton, Modal, TextField, Button, Switch } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { UserContext } from '../context/UserProvider';
import { db } from '../config/firebase';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';

const Art = () => {
  const { user } = useContext(UserContext);
  const [artItems, setArtItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedArt, setSelectedArt] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [isHidden, setIsHidden] = useState(false);
  const [playingAudio, setPlayingAudio] = useState(null);
  const audioRef = useRef({});

  useEffect(() => {
    const fetchArtItems = async () => {
      if (user) {
        try {
          const inventoryQuery = query(collection(db, 'Inventory'), 
            where('userId', '==', user.uid),
            where('onChain', '==', false)
          );
          const inventorySnapshot = await getDocs(inventoryQuery);

          const inventoryIds = inventorySnapshot.docs.map(doc => doc.id);

          const gemsQuery = query(collection(db, 'Gems'),
            where('inventoryId', 'in', inventoryIds)
          );
          const gemsSnapshot = await getDocs(gemsQuery);

          const pinnedIds = new Set(gemsSnapshot.docs.map(doc => doc.data().inventoryId));

          const fetchedArtItems = inventorySnapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data,
              isPinned: pinnedIds.has(doc.id),
              isAudio: data.audio ? true : false
            };
          });

          setArtItems(fetchedArtItems);
        } catch (error) {
          console.error("Error fetching art items:", error);
        }
      }
    };

    fetchArtItems();
  }, [user]);

  const handleOpen = (art) => {
    setSelectedArt(art);
    setEditedTitle(art.title);
    setEditedDescription(art.description);
    setIsHidden(art.isHidden || false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedArt(null);
    setPlayingAudio(null);
  };

  const handleSave = async () => {
    if (selectedArt && user && selectedArt.userId === user.uid) {
      try {
        const artRef = doc(db, 'Inventory', selectedArt.id);
        await updateDoc(artRef, {
          title: editedTitle,
          description: editedDescription,
          isHidden: isHidden,
        });
        
        setArtItems(artItems.map(item => 
          item.id === selectedArt.id 
            ? { ...item, title: editedTitle, description: editedDescription, isHidden: isHidden }
            : item
        ));
        
        handleClose();
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  };

  const handlePlayPause = (artId) => {
    if (playingAudio === artId) {
      audioRef.current[artId].pause();
      setPlayingAudio(null);
    } else {
      if (playingAudio) {
        audioRef.current[playingAudio].pause();
      }
      audioRef.current[artId].play();
      setPlayingAudio(artId);
    }
  };

  return (
    <Box sx={{ 
        width: '75%',
        mx: 'auto',
        overflow: 'auto',
        maxHeight: '50vh',
        bgcolor: '#000000',
        p: 2,
        borderRadius: 2,
        '&::-webkit-scrollbar': { display: 'none' },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
      }}
    >
      <Typography variant="h6" sx={{ textAlign: 'center', mb: 2 }}>
        Your Art Inventory
      </Typography>

      {artItems.length > 0 ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'space-around' }}>
          {artItems.map((art) => (
            <Card key={art.id} sx={{ width: '45%', maxWidth: 300, cursor: 'pointer' }} onClick={() => handleOpen(art)}>
              {art.isAudio ? (
                <Box sx={{ height: 140, display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#262626' }}>
                  <IconButton onClick={(e) => {
                    e.stopPropagation();
                    handlePlayPause(art.id);
                  }}>
                    {playingAudio === art.id ? <PauseIcon /> : <PlayArrowIcon />}
                  </IconButton>
                  <audio 
                    ref={el => audioRef.current[art.id] = el} 
                    src={art.audio} 
                    onEnded={() => setPlayingAudio(null)}
                  />
                </Box>
              ) : (
                <CardMedia component="img" height="140" image={art.image} alt={art.title} />
              )}
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body1">{art.title}</Typography>
                  {art.isPinned && <LocationOnIcon color="primary" />}
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {art.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      ) : (
        <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center' }}>
          No Art found.
        </Typography>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-art-modal"
        aria-describedby="modal-to-edit-art-details"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          <Typography id="edit-art-modal" variant="h6" component="h2" mb={2}>
            Edit Art Details
          </Typography>
          <TextField
            fullWidth
            label="Title"
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Description"
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
            margin="normal"
            multiline
            rows={4}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Typography variant="body2" sx={{ mr: 2 }}>Hide art until you're near it:</Typography>
            <Switch
              checked={isHidden}
              onChange={(e) => setIsHidden(e.target.checked)}
              inputProps={{ 'aria-label': 'hide art until near' }}
            />
          </Box>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose} sx={{ mr: 1 }}>Cancel</Button>
            <Button onClick={handleSave} variant="contained">Save</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Art;