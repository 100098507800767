
import React from 'react';

const GetLast6Digits = (address) => {

    // Ensure the address is a valid Ethereum address
    if (typeof address !== 'string' || !/^0x[a-fA-F0-9]{40}$/.test(address)) {
      throw new Error('Invalid Ethereum address');
    }
  
    // Get the last 6 digits of the address
    return address.slice(-6);

  }

  export default GetLast6Digits