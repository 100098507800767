import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardMedia, CardContent } from '@mui/material';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import WalletStatus from "./WalletStatus";
import LoadingSpinner from './LoadingSpinner';

const NFTS = () => {
  const { account, library, active } = useWeb3React();
  const [nfts, setNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasNFTs, setHasNFTs] = useState(null);

  useEffect(() => {
    if (active && account && library) {
      checkNFTs();
    }
  }, [account, library]);
  
  const checkNFTs = async () => {
    setIsLoading(true);
    const contractAddress = "0xf14507959deA7272d7A97F9B5D700b7E9D48EF1F";
    const abi = [
      "function balanceOf(address owner) view returns (uint256)"
    ];

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(contractAddress, abi, provider);
      const balance = await contract.balanceOf(account);
      
      if (balance.toNumber() > 0) {
        setHasNFTs(true);
        fetchNFTs();
      } else {
        setHasNFTs(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error checking NFTs:', error);
      setIsLoading(false);
    }
  };


  const fetchNFTs = async () => {
    const contractAddress = "0xf14507959deA7272d7A97F9B5D700b7E9D48EF1F"; // Update with your contract address
    const abi = [
        "function tokenURI(uint256 tokenId) view returns (string memory)",
        "function balanceOf(address owner) view returns (uint256)",
        "function tokenOfOwnerByIndex(address owner, uint256 index) view returns (uint256)"
    ];

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const account = await signer.getAddress();
    const contract = new ethers.Contract(contractAddress, abi, provider);

    try {
        const balance = await contract.balanceOf(account);
        console.log("account",account)
        if (balance.toNumber() === 0) return setIsLoading(false);

        const nftPromises = [];

        for (let i = 0; i < balance.toNumber(); i++) {
            const tokenId = await contract.tokenOfOwnerByIndex(account, i);
            console.log("Token ID:", tokenId.toString());
            const tokenURI = await contract.tokenURI(tokenId);
            nftPromises.push(fetch(tokenURI).then(response => response.json()));
        }

        const nfts = await Promise.all(nftPromises);
        setNfts(nfts);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching NFTs:', error);
        setIsLoading(false);
    }
};

  return (
    <Box
     sx={{
      width: '75%',
      mx: 'auto',
      overflow: 'auto',
      maxHeight: '100vh',
      bgcolor: '#000000',
      p: 2,
      borderRadius: 2,
      '&::-webkit-scrollbar': { display: 'none' },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none'
    }}>
      {!active && <WalletStatus />}
      
      {active && isLoading && <LoadingSpinner height="20vh" />}
      
      {active && !isLoading && hasNFTs === false && (
        <Box>
          <Typography variant="body1">No NFTs found.</Typography>
        </Box>
      )}
            {active && !isLoading && hasNFTs && (

          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {nfts.map((nft, index) => (
              <Card key={index} sx={{ width: "45%", maxWidth: "200px" }}>
                <CardMedia component="img" image={nft.image} alt={`NFT ${index}`} />
                <CardContent>
                  <Typography variant="body2">{nft.title}</Typography>
                  <Typography variant="body2">{nft.description}</Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
      )}
      </Box>
  );
};

export default NFTS;