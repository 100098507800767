import React, { useState, useEffect, useContext } from 'react';
import { GoogleMap, useLoadScript, OverlayView, MarkerF } from '@react-google-maps/api';
import { useWeb3React } from '@web3-react/core';
import { Box, Typography, IconButton, Modal, Button, CardMedia } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WalletIcon from '@mui/icons-material/Wallet';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import { Link } from 'react-router-dom';
import { db } from '../config/firebase'; // Adjust the import path accordingly
import { collection, getDocs, doc, getDoc, updateDoc, query, where, deleteDoc } from "firebase/firestore";
import { ethers } from 'ethers';
import { InjectedConnector } from '@web3-react/injected-connector';
import FiberPinIcon from '@mui/icons-material/FiberPin';
import PushPinIcon from '@mui/icons-material/PushPin';
import { PinDrop } from '@mui/icons-material';
import placeHolderImage from '../assets/bjork.jpeg';
import NftIcon from "../assets/nftPin.png";
import Icon from "../assets/icon.png";
import { UserContext } from '../context/UserProvider';
import SelectedGem from '../components/SelectedGem';
import LoadingSpinner from '../components/LoadingSpinner';
import Pin from '../components/Pin';

const mapContainerStyle = {
  width: '100vw',
  height: '100vh',
};

const options = {
  disableDefaultUI: true,
  zoomControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  styles: [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#181818"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1b1b1b"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#2c2c2c"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8a8a8a"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#373737"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3c3c3c"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#4e4e4e"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3d3d3d"
        }
      ]
    }
  ]
};
const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 43114, 43113] // Mainnet, Ropsten, Rinkeby, Goerli, Kovan, Avalanche Mainnet, Fuji Testnet
});
// const gradientMarkerStyle = {
//   position: 'absolute',
//   width: '20px',
//   height: '20px',
//   borderRadius: '50%',
//   background: 'radial-gradient(circle, rgba(255,94,0,1) 0%, rgba(33,33,33,1) 100%)',
// };

const center = {
  lat: 41.8781,
  lng: -87.6298,
};

const Home = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCAMU56Mr8co3Nyrvo7pHYMw10xWTAehuM',
  });
  const { activate, account, library, chainId, active, deactivate } = useWeb3React();
  const [errors, setErrors] = useState();
  const [currentPosition, setCurrentPosition] = useState(null);
  const [centeredPosition, setCenteredPosition] = useState(center);
  const [isPinMode, setIsPinMode] = useState(false);
  const [pinPosition, setPinPosition] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gems, setGems] = useState([]);
  const [selectedGem, setSelectedGem] = useState(null);
  const [isCollectModalOpen, setIsCollectModalOpen] = useState(false);
  const [mapRef, setMapRef] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const { user } = useContext(UserContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [gemIsLoading, setGemIsLoading] = useState(false);

  useEffect(() => {
  
    if (gems.length === 0) {
      fetchGems();
    }
  
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const newPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setCurrentPosition(newPosition);
  
        if (initialLoad) {
          setCenteredPosition(newPosition);
          setInitialLoad(false);
        }
      },
      (error) => console.log(error),
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
    );
  
    return () => navigator.geolocation.clearWatch(watchId);
  }, [gems.length, initialLoad]);

  const fetchGems = async () => {
    // Fetch gems from Firebase
    try {
      const gemsCollection = collection(db, 'Gems');
      const gemsSnapshot = await getDocs(gemsCollection);
      const firebaseGems = gemsSnapshot.docs.map(doc => doc.data());
      setGems(firebaseGems);
    } catch (error) {
      console.error('Error fetching gems from Firebase:', error);
    }  
  };

  useEffect(() => {
    if (selectedGem && isCollectModalOpen && selectedGem.inventoryId) {
      // setGemIsLoading(true);
      const fetchMetadata = async () => {
        const inventoryDoc = doc(db, 'Inventory', selectedGem.inventoryId);
        const inventorySnapshot = await getDoc(inventoryDoc);
        if (inventorySnapshot.exists()) {
          const data = inventorySnapshot.data();
          
          // Fetch user data
          const userDoc = doc(db, 'Users', data.userId);
          const userSnapshot = await getDoc(userDoc);
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            setSelectedGem((prevGem) => ({
              ...prevGem,
              ...data,
              userName: userData.username,
              userImage: userData.profileImage,
            }));
            // setGemIsLoading(false);
          }
        }
      };

      fetchMetadata();

      }

  
  }, [selectedGem, isCollectModalOpen]);

  useEffect(() => {
    fetchGemsOnChain();
  }, [active]);

  const fetchGemsOnChain = async () => {
    try {
      const contractAddress = "0xf14507959deA7272d7A97F9B5D700b7E9D48EF1F"; // Update with your contract address
      const abi = [
        "function totalSupply() view returns (uint256)",
        "function tokenByIndex(uint256 index) view returns (uint256)",
        "function tokenURI(uint256 tokenId) view returns (string memory)",
        "function getCollectableGemLocation(uint256 tokenId) view returns (string memory, string memory)",
        "function ownerOf(uint256 tokenId) view returns (address)"
      ];

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(contractAddress, abi, provider);
      const totalSupply = await contract.totalSupply();
      const gemPromises = [];

      for (let i = 0; i < totalSupply; i++) {
        gemPromises.push((async (index) => {
          try {
            const tokenId = await contract.tokenByIndex(index);
            const tokenURI = await contract.tokenURI(tokenId);
            const [latitudeString, longitudeString] = await contract.getCollectableGemLocation(tokenId);
            const owner = await contract.ownerOf(tokenId);
            const latitude = parseFloat(latitudeString);
            const longitude = parseFloat(longitudeString);
            const response = await fetch(tokenURI);
            const metadata = await response.json();

            return {
              tokenId: tokenId,
              owner,
              latitude,
              longitude,
              ...metadata
            };
          } catch (error) {
            console.error(`Error fetching data for tokenId ${index}:`, error);
            return null; // Return null or an appropriate fallback value
          }
        })(i));
      }

      const gemsFromContract = await Promise.all(gemPromises);
      const validGems = gemsFromContract.filter(gem => gem !== null);
      setGems(prevGems => [...prevGems, ...validGems]);
    } catch (error) {
      console.error('Error fetching gems from the smart contract:', error);
    }
  }
  
  const handleConnect = () => {
    activate(injected);
  };

  const handleLoad = (map) => {
    setMapRef(map);
  };
  
  const handleLocationClick = () => {
    if (currentPosition && mapRef) {
      setCenteredPosition(currentPosition);
      mapRef.panTo(currentPosition);
    }
  };
  
  const collect = async (tokenId) => {

    if(tokenId){
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contractAddress = "0xf14507959deA7272d7A97F9B5D700b7E9D48EF1F"; // Replace with your contract address
      const abi = [
          "function collectGem(uint256 tokenId) public"
      ];
      const contract = new ethers.Contract(contractAddress, abi, signer);

      try {
          // const tx = await contract.collectGem(tokenId);
          const tx = await contract.collectGem(tokenId);
          await tx.wait();
          fetchGemsOnChain();
          return true;
      } catch (error) {
          console.error('Error collecting gem:', error);
          setErrors(error);
          console.log(error.data);
          return false;
      }
    }else{
      const gemRef = doc(db, 'Inventory', selectedGem.inventoryId);
      await updateDoc(gemRef, { userId: user.uid });
      const gemDoc = query(collection(db, 'Gems'), where('inventoryId', '==', selectedGem.inventoryId));
      const gemSnapshot = await getDocs(gemDoc);
      gemSnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
      console.log('Gem collected successfully!');
    }

};

  const handleMapClick = (event) => {
    if (isPinMode) {
      setPinPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });
      setIsModalOpen(true);
    }
  };

  const handleCollectClick = (gem) => () => {
    setSelectedGem(gem);
    setIsCollectModalOpen(true);
  };

  const handleCloseCollectModal = () => {
    setSelectedGem(null);
    setIsCollectModalOpen(false);
  };

  const handlePinClick = () => {
    setIsPinMode((prevMode) => !prevMode);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsPinMode(false);
    setSelectedGem(null);
  };

  const calculateDistanceMeters = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // Earth radius in meters
    const φ1 = lat1 * Math.PI / 180;
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;
  
    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    const distanceMeters = R * c;
    return distanceMeters;
  };
  
  const canCollect = (user, owner, gemLat, gemLng, NFTowner) => {
    if (user === owner) return false;
    if (!currentPosition) return false;
    if (NFTowner){
      if (!account) return false;
      if (account === NFTowner ) return false;
    }
    const userLat = currentPosition.lat;
    const userLng = currentPosition.lng;
    const distance = calculateDistanceMeters(userLat, userLng, gemLat, gemLng);
    const canCollect =  distance <= 15 ? true : false;
    return canCollect
  };

  const distanceAway = (gemLat, gemLng) => {
    if (!currentPosition) return 'Calculating...';
  
    const userLat = currentPosition.lat;
    const userLng = currentPosition.lng;
  
    return calculateDistance(userLat, userLng, gemLat, gemLng);
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI/180;
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (lon2-lon1) * Math.PI/180;
  
    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  
    const distanceMeters = R * c; // in metres
    const distanceMiles = distanceMeters / 1609.34; // in miles
    const distanceFeet = distanceMeters * 3.28084; // in feet
  
    if (distanceFeet < 5280) {
      return `${distanceFeet.toFixed(2)} feet away`;
    } else {
      return `${distanceMiles.toFixed(2)} miles away`;
    }
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <LoadingSpinner height="100vh" />;
  console.log("selectedGem",selectedGem);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box sx={{ position: 'relative', height: '95vh', width: '100vw', bgcolor: 'black' }}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={centeredPosition}
        options={options}
        onClick={handleMapClick}
        onLoad={handleLoad}
      >
        <MarkerF 
          position={currentPosition} 
          icon={Icon}
        />

        {pinPosition && <MarkerF position={pinPosition} />}
        {gems.map((gem, index) => (
          <MarkerF
            key={index}
            icon={gem.owner ? NftIcon : ""}
            position={{ lat: gem.latitude, lng: gem.longitude }}
            onClick={handleCollectClick(gem)}
          />
        ))}
      </GoogleMap>

      <Box sx={{ position: 'absolute', top: 16, left: 16 }}>
        <Typography
          variant="h6"
          sx={{
            display: 'flex',
            alignItems: 'center',
            bgcolor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            borderRadius: 2,
            p: 1,
          }}
        > {currentPosition ?
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <WalletIcon onClick={handleConnect} sx={{ mr: 1 }} />{active ? 'Smart wallet' : 'Connect Wallet'}
          </Box>:
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Typography sx={{ mr: 1 }}>turn on Location</Typography>
          </Box>
          }
        </Typography>
      </Box>

      <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
        <Link to="/profile">
          <IconButton sx={{ bgcolor: 'rgba(0, 0, 0, 0.7)', color: 'white', borderRadius: '50%' }}>
            <AccountCircleIcon />
          </IconButton>
        </Link>
      </Box>

      <Box sx={{ position: 'absolute', bottom: 16, left: 16 }}>
        <Button
          variant="contained"
          onClick={handlePinClick}
          sx={{ bgcolor: 'rgba(0, 0, 0, 0.7)', color: 'white', borderRadius: '50%' }}
        >
          {isPinMode ? <FiberPinIcon/> : <PushPinIcon/>}
        </Button>
      </Box>

      <Box sx={{ position: 'absolute', bottom: 16, right: 16 }}>
        <IconButton
          onClick={handleLocationClick}
          sx={{ bgcolor: 'rgba(0, 0, 0, 0.7)', color: 'white', borderRadius: '50%' }}
        >
          <LocationOnIcon />
        </IconButton>
      </Box>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'white',
            p: 4,
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
     <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Pin fetchGemsOnChain={fetchGemsOnChain} fetchGems={fetchGems} handleCloseModal={handleCloseModal} pinnedPosition={pinPosition} />
      </Modal>
        </Box>
      </Modal>

      <SelectedGem user={user} selectedGem={selectedGem} isCollectModalOpen={isCollectModalOpen} handleCloseCollectModal={handleCloseCollectModal} toggleExpand={toggleExpand} distanceAway={distanceAway} canCollect={canCollect} collect={collect} gemIsLoading={gemIsLoading} />

    </Box>
  );
};

export default Home;