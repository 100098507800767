import React, { useContext, useState, useEffect, useRef } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Button, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { UserContext } from '../context/UserProvider';
import { db } from '../config/firebase';
import { collection, query, where, getDocs, addDoc, doc, updateDoc } from 'firebase/firestore';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import WalletStatus from "./WalletStatus";
import { Link } from 'react-router-dom';

const Pin = ({handleCloseModal, pinnedPosition, fetchGemsOnChain, fetchGems}) => {
  const { user } = useContext(UserContext);
  const [nfts, setNfts] = useState([]);
  const [artCollection, setArt] = useState([]);
  const { account, library } = useWeb3React();
  const [isLoading, setIsLoading] = useState(true);
  const [playingAudio, setPlayingAudio] = useState(null);
  const audioRef = useRef({});

  useEffect(() => {
    if (account && library) {
      fetchNFTs();
    }
  }, [account, library, user]);

  useEffect(() => {
    setIsLoading(true);
    const fetchArt = async () => {
      if (user) {
        const q = query(
          collection(db, 'Inventory'),
          where('userId', '==', user.uid),
          where('isCollectable', '==', false),
          where('onChain', '==', false)
        );
        const querySnapshot = await getDocs(q);
        const fetchedArt = [];
        querySnapshot.forEach((doc) => {
          fetchedArt.push({ 
            id: doc.id, 
            ...doc.data(),
            isAudio: doc.data().audio ? true : false 
          });
        });
        setArt(fetchedArt);
        setIsLoading(false);
      }
    };

    fetchArt();
  }, [user]);


  const handlePlayPause = (artId) => {
    if (playingAudio === artId) {
      audioRef.current[artId].pause();
      setPlayingAudio(null);
    } else {
      if (playingAudio) {
        audioRef.current[playingAudio].pause();
      }
      audioRef.current[artId].play();
      setPlayingAudio(artId);
    }
  };

  const fetchNFTs = async () => {
    setIsLoading(true);
    const contractAddress = "0xf14507959deA7272d7A97F9B5D700b7E9D48EF1F"; // Update with your contract address
    const abi = [
        "function tokenURI(uint256 tokenId) view returns (string memory)",
        "function balanceOf(address owner) view returns (uint256)",
        "function tokenOfOwnerByIndex(address owner, uint256 index) view returns (uint256)"
    ];
  
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const account = await signer.getAddress();
    const contract = new ethers.Contract(contractAddress, abi, provider);
  
    try {
        const balance = await contract.balanceOf(account);
        
        if (balance.toNumber() === 0) return setIsLoading(false);
  
        const nftPromises = [];
  
        for (let i = 0; i < balance.toNumber(); i++) {
            const tokenId = await contract.tokenOfOwnerByIndex(account, i);
            console.log("Token ID:", tokenId.toString());
            const tokenURI = await contract.tokenURI(tokenId);
            const nftMetadata = await fetch(tokenURI).then(response => response.json());
            nftMetadata.tokenId = tokenId // Include the tokenId in the metadata
            nftPromises.push(nftMetadata);
        }
  
        const nfts = await Promise.all(nftPromises);
        setNfts(nfts);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching NFTs:', error);
        setIsLoading(false);
    }
  };

  const handlePinArt = async (art) => {
    if (!user || !pinnedPosition) return;
  
    const newGem = {
      userId: user.uid,
      name: art.title,
      description: art.description,
      inventoryId: art.id,
      longitude: pinnedPosition.lng,
      latitude: pinnedPosition.lat,
      isAudio: art.isAudio,
      [art.isAudio ? 'audio' : 'image']: art[art.isAudio ? 'audio' : 'image']
    };
  
    try {
      await addDoc(collection(db, 'Gems'), newGem);
      const inventoryRef = doc(db, 'Inventory', art.id);
      await updateDoc(inventoryRef, {
        isCollectable: true
      });
      fetchGems();
      handleCloseModal();
    } catch (error) {
      console.error('Error pinning art or updating inventory:', error);
    }
  };

  const handlePinNFT = async (nft) => {
    if (!user || !pinnedPosition) return;
  
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contractAddress = "0xf14507959deA7272d7A97F9B5D700b7E9D48EF1F"; // Replace with your contract address
    const abi = [
      "function pinGem(uint256 tokenId, string memory latitude, string memory longitude) public"
    ];
    const contract = new ethers.Contract(contractAddress, abi, signer);
  
    try {
      const latitude = pinnedPosition.lat.toString();
      const longitude = pinnedPosition.lng.toString();
      
      console.log('Pinning NFT...', nft.tokenId, latitude, longitude);
      const tx = await contract.pinGem(nft.tokenId, latitude, longitude);
      await tx.wait(); // Wait for the transaction to be mined
  
      // Prepare the gem data to store in Firestore
      const newInventoryItem = {
        userId: user.uid,
        name: nft.title,
        description: nft.description,
        onChain: true,
        ownerAddress: await signer.getAddress(),
        tokenId: nft.tokenId,
        contractAddress: "0xf14507959deA7272d7A97F9B5D700b7E9D48EF1F"
      };
  
      // Store the gem data in Firestore
      await addDoc(collection(db, 'Inventory'), newInventoryItem);
      handleCloseModal();
      fetchGemsOnChain();
    } catch (error) {
      console.error('Error pinning NFT:', error);
    }
  };
  

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        width: '99%',
        height: '70%',
        borderTopLeftRadius: 20,
        color: 'white',
        backgroundColor: '#000404',
        borderTopRightRadius: 20,
        overflowY: 'auto',
        boxShadow: 3,
        '&::-webkit-scrollbar': { display: 'none' },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
      }}
    >
      <Typography variant="h3" sx={{ textAlign: 'left', mb: 2, m: 5 }}>
        inventory
      </Typography>
      {artCollection.length > 0 ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
          {artCollection.map((art) => (
            <Card key={art.id} sx={{ 
              width: '45%',
              backgroundColor: '#414141',
              color: 'white',
             }}>
              {art.isAudio ? (
                <Box sx={{ height: 140, display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#262626' }}>
                  <IconButton onClick={(e) => {
                    e.stopPropagation();
                    handlePlayPause(art.id);
                  }}>
                    {playingAudio === art.id ? <PauseIcon /> : <PlayArrowIcon />}
                  </IconButton>
                  <audio 
                    ref={el => audioRef.current[art.id] = el} 
                    src={art.audio} 
                    onEnded={() => setPlayingAudio(null)}
                  />
                </Box>
              ) : (
                <CardMedia component="img" height="140" image={art.image} alt={art.title} />
              )}
              <CardContent>
                <Typography variant="body1">{art.title}</Typography>
                <Typography variant="body2" >
                  {art.description}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 1 }}
                  onClick={() => handlePinArt(art)}
                >
                  Pin Art
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>
      ) : (
        <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'left', mb: 2, m: 5, color: 'azure' }}>
          nothing here , <Link style={{ color:'#845dff' }} to={'/profile'}>create something</Link>
        </Typography>
      )}

      <Typography variant="h3" sx={{ textAlign: 'left', mb: 2, m: 5 }}>
        your nfts
      </Typography>

      {nfts.length === 0 && !isLoading && account ?
        <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'left', m: 5, color: 'azure' }}>
          You have no NFTs
        </Typography>:
        <Box sx={{ m: 5, display: 'flex', justifyContent: 'left', alignItems: 'left', textAlign: 'left' }}>
          {account == undefined && <WalletStatus contentAlign={'left'} color={'#845dff'} />}
        </Box>
      }

      {nfts.length > 0 ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
          {nfts.map((nft, index) => (
            <Card key={index} sx={{ 
                width: '45%',
                backgroundColor: '#414141',
                color: 'white',
              }}>
              <CardMedia component="img" height="140" image={nft.image} alt={`NFT ${index}`} />
              <CardContent>
                <Typography variant="body1">{nft.title}</Typography>
                <Typography variant="body2">
                  {nft.description}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 1 }}
                  onClick={() => handlePinNFT(nft)}
                >
                  Pin NFT
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>
      ) : ""
      }
      {isLoading ?         
        <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'left', m: 5, color: 'azure' }}>
            Loading...
        </Typography> : ""
      }

      <Button style={{display: 'block', margin: "20px auto",backgroundColor: 'antiquewhite', width: '85%'}} onClick={handleCloseModal}>
        Close
      </Button>
    </Box>
  );
};

export default Pin;