import React, { useState, useRef } from 'react';
import { Box, CardMedia, Typography, Button, TextField, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { storage, db } from '../config/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import WalletStatus from "./WalletStatus";
import LoadingSpinner from './LoadingSpinner';

const Create = ({ user, handleClose }) => {
  const { account, library, active } = useWeb3React();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [isAudio, setIsAudio] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [metadata, setMetadata] = useState({
    title: '',
    description: '',
    attributes: [{ trait_type: '', value: '' }]
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    const allowedTypes = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg', 'audio/mpeg', 'audio/mp3', 'audio/wav'];
  
    if (!allowedTypes.includes(file.type)) {
      alert('Invalid file type. Only PNG, GIF, JPEG, JPG, MP3, and WAV files are allowed.');
      return;
    }
  
    const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB
    if (file.size > maxSizeInBytes) {
      alert('File size exceeds the limit of 10 MB.');
      return;
    }
  
    setSelectedFile(file);
    setIsAudio(file.type.startsWith('audio/'));
  
    if (isAudio) {
      setFilePreview(URL.createObjectURL(file));
    } else {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleMetadataChange = (e, index, field) => {
    const newAttributes = [...metadata.attributes];
    newAttributes[index][field] = e.target.value;
    setMetadata({ ...metadata, attributes: newAttributes });
  };

  const handleAddAttribute = () => {
    setMetadata({ ...metadata, attributes: [...metadata.attributes, { trait_type: '', value: '' }] });
  };

  const saveMetadataToFirestore = async (nftMetadata, metadataURL) => {
    const inventoryDoc = doc(db, 'Inventory', selectedFile.name);
    await setDoc(inventoryDoc, {
      userId: user.uid,
      isCollectable: false,
      isHidden: false,
      onChain: false,
      metadataURL,
      ...nftMetadata
    });

    setSelectedFile(null);
    setFilePreview(null);
    setMetadata({ title: '', description: '', attributes: [{ trait_type: '', value: '' }] });

    console.log('NFT saved successfully!');
  };

  const saveMintToFirestore = async (signer, contractAddress, metadataURL) => {
    const inventoryDoc = doc(db, 'Inventory', selectedFile.name);
    await setDoc(inventoryDoc, {
      userId: user.uid,
      isCollectable: false,
      onChain: true,
      metadataURL,
      ownerAddress: signer,
      contractAddress: contractAddress,
      tokenId: 0
    });

    setSelectedFile(null);
    setFilePreview(null);
    setMetadata({ title: '', description: '', attributes: [{ trait_type: '', value: '' }] });

    console.log('NFT saved successfully!');
  };

  const handleSaveArt = async () => {
    if (!selectedFile) return;
    setLoading(true);
    try {
      const storageRef = ref(storage, `nfts/${selectedFile.name}`);
      await uploadBytes(storageRef, selectedFile);
      const fileURL = await getDownloadURL(storageRef);

      const nftMetadata = {
        ...metadata,
        [isAudio ? 'audio' : 'image']: fileURL
      };

      const metadataBlob = new Blob([JSON.stringify(nftMetadata)], { type: 'application/json' });
      const metadataRef = ref(storage, `metadata/${selectedFile.name}.json`);
      await uploadBytes(metadataRef, metadataBlob);
      const metadataURL = await getDownloadURL(metadataRef);
      
      await saveMetadataToFirestore(nftMetadata, metadataURL);
      setLoading(false);
    } catch (error) {
      console.error('Error saving NFT:', error);
      setLoading(false);
    }
  };

  const handleMintNFT = async () => {
    if (!selectedFile) return;
    setLoading(true);
    try {
      const storageRef = ref(storage, `nfts/${selectedFile.name}`);
      await uploadBytes(storageRef, selectedFile);
      const fileURL = await getDownloadURL(storageRef);

      const nftMetadata = {
        ...metadata,
        [isAudio ? 'audio' : 'image']: fileURL
      };

      const metadataBlob = new Blob([JSON.stringify(nftMetadata)], { type: 'application/json' });
      const metadataRef = ref(storage, `metadata/${selectedFile.name}.json`);
      await uploadBytes(metadataRef, metadataBlob);
      const metadataURL = await getDownloadURL(metadataRef);

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contractAddress = "0xf14507959deA7272d7A97F9B5D700b7E9D48EF1F"; // Update with your contract address
      const abi = [
        "function safeMint(address to, string memory uri) public"
      ];
      const contract = new ethers.Contract(contractAddress, abi, signer);

      const tx = await contract.safeMint(await signer.getAddress(), metadataURL);
      const receipt = await tx.wait();
      console.log(receipt);

      await saveMintToFirestore(receipt.from, receipt.to, metadataURL);
      setLoading(false);
    } catch (error) {
      console.error('Error minting NFT:', error);
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: '90%',
        mx: 'auto',
        marginTop: "20px",
        overflow: 'auto',
        maxHeight: '60vh',
        bgcolor: '#000000',
        padding: "15px 20px 15px 20px",
        borderRadius: 2,
        '&::-webkit-scrollbar': { display: 'none' },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
      }}
    >
      <Typography variant="h3" textAlign={"center"} fontFamily={"wood"} letterSpacing={2} color="white">create art piece</Typography>
      <Button
        variant="contained"
        component="label"
        sx={{
          mt: 2,
          bgcolor: '#7500e1',
          color: 'white',
          width: '100%',
          padding: '15px',
          '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.2)' }
        }}
      >
        {filePreview ? 'Change File' : 'Select File'}
        <input type="file" hidden onChange={handleFileChange} />
      </Button>
      {filePreview && (
        <Box sx={{ mt: 2, bgcolor: '#262626', p: 2, borderRadius: 2 }}>
          {isAudio ? (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <IconButton onClick={handlePlayPause}>
                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
              </IconButton>
              <audio ref={audioRef} src={filePreview} onEnded={() => setIsPlaying(false)} />
            </Box>
          ) : (
            <CardMedia component="img" height="200" image={filePreview} alt="NFT Preview" />
          )}
        </Box>
      )}
      <TextField
        fullWidth
        label="Title"
        variant="outlined"
        sx={{
          mt: 2,
          input: { color: 'white' },
          label: { color: 'gray' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: 'gray' },
            '&:hover fieldset': { borderColor: 'white' }
          }
        }}
        value={metadata.title}
        onChange={(e) => setMetadata({ ...metadata, title: e.target.value })}
      />
      <TextField
        fullWidth
        label="Description"
        variant="outlined"
        sx={{
          mt: 2,
          input: { color: 'white' },
          label: { color: 'gray' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: 'gray' },
            '&:hover fieldset': { borderColor: 'white' }
          }
        }}
        value={metadata.description}
        onChange={(e) => setMetadata({ ...metadata, description: e.target.value })}
      />
      {metadata.attributes.map((attr, index) => (
        <Box key={index} sx={{ display: 'flex', gap: 2, mt: 2 }}>
          <TextField
            fullWidth
            label="Trait Type"
            variant="outlined"
            sx={{
              input: { color: 'white' },
              label: { color: 'gray' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'gray' },
                '&:hover fieldset': { borderColor: 'white' }
              }
            }}
            value={attr.trait_type}
            onChange={(e) => handleMetadataChange(e, index, 'trait_type')}
          />
          <TextField
            fullWidth
            label="Value"
            variant="outlined"
            sx={{
              input: { color: 'white' },
              label: { color: 'gray' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'gray' },
                '&:hover fieldset': { borderColor: 'white' }
              }
            }}
            value={attr.value}
            onChange={(e) => handleMetadataChange(e, index, 'value')}
          />
        </Box>
      ))}
      <Button
        variant="contained"
        onClick={handleAddAttribute}
        disabled={loading}
        sx={{
          mt: 2,
          bgcolor: 'rgba(255, 255, 255, 0.1)',
          color: 'white',
          width: '100%',
          '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.2)' }
        }}
      >
        Add Attribute
      </Button>
      <Button
        variant="contained"
        onClick={handleSaveArt}
        disabled={!selectedFile || !metadata.title || loading}
        sx={{
          mt: 2,
          bgcolor: 'rgba(255, 255, 255, 0.1)',
          color: 'white',
          width: '100%',
          '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.2)' }
        }}
      >
        SAVE NFT
      </Button>
      {loading && (
        <LoadingSpinner height={100} />
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        {active ?
          <Box>
            <Typography variant="h6" color="white" fontFamily={'Poppins, sans-serif'} mt={2}>create an nft</Typography>
          </Box>:""
        }

        {active && !loading ?
            <Button
            variant="contained"
            onClick={handleMintNFT}
            disabled={!selectedFile || !metadata.title}
            sx={{
              mt: 2,
              bgcolor: 'rgba(255, 255, 255, 0.1)',
              color: 'white',
              width: '100%',
              '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.2)' }
            }}
            >
              MINT NFT
            </Button>:
            <WalletStatus/>
          }
      </Box>
    </Box>
  );
};

export default Create;
