import React, { useState, useContext, useEffect, useRef } from 'react';
import { Box, CardMedia, Typography, Button, Tab, Tabs, TextField, Modal, Slide, CardContent, Card, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { UserContext } from '../context/UserProvider';
import { db, storage } from '../config/firebase';
import { doc, getDoc, updateDoc, query, where, getDocs, collection } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { ethers } from 'ethers';

const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 43114, 43113]
});

const Gems = ({ open, handleClose, handleOpen }) => {
  const { user } = useContext(UserContext);
  const [walletAddress, setWalletAddress] = useState('');
  const { activate, library, account } = useWeb3React();
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [art, setArt] = useState([]);
  const [playingAudio, setPlayingAudio] = useState(null);
  const audioRef = useRef({});

  const fetchArt = async () => {
    if (user) {
      try {
        const q = query(
          collection(db, 'Inventory'),
          where('userId', '==', user.uid),
          where('isCollectable', '==', true)
        );
        const querySnapshot = await getDocs(q);
        const fetchedArt = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          fetchedArt.push({ 
            id: doc.id, 
            ...data,
            isAudio: data.audio ? true : false 
          });
        });
        setArt(fetchedArt);
      } catch (error) {
        console.error('Error fetching art:', error);
      }
    }
  };

  useEffect(() => {
    if (user) {
      fetchArt();
      const checkWallet = async () => {
        const userDoc = doc(db, 'Users', user.uid);
        const userDocSnapshot = await getDoc(userDoc);
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          if (userData.walletAddress) {
            setWalletAddress(userData.walletAddress);
          }
        }
      };
      checkWallet();
    }
  }, [user]);

  const handleConnectWallet = async () => {
    try {
      await activate(injected);
      if (library) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        setWalletAddress(address);

        const userDoc = doc(db, 'Users', user.uid);
        await updateDoc(userDoc, { walletAddress: address });
      }
    } catch (error) {
      console.error('Error connecting with MetaMask:', error);
    }
  };

  const handlePlayPause = (artId) => {
    if (playingAudio === artId) {
      audioRef.current[artId].pause();
      setPlayingAudio(null);
    } else {
      if (playingAudio) {
        audioRef.current[playingAudio].pause();
      }
      audioRef.current[artId].play();
      setPlayingAudio(artId);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            transform: 'translateX(-50%)',
            width: '90%',
            mx: 'auto',
            overflow: 'auto',
            bgcolor: 'azure',
            height: '80%',
            padding: "15px 20px 15px 20px",
            borderRadius: 2,
            '&::-webkit-scrollbar': { display: 'none' },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none'
          }}
        >
          <Tabs value={0} centered>
            <Tab sx={{ color: 'white' }} label="Pinned Gems" /> 
          </Tabs>

          <Typography variant="h6" sx={{ textAlign: 'center', mt: 2, fontSize: '0.9rem' }}>
            pinned art can only be re-collected if ur near it.
          </Typography>

          <Box sx={{ width: '100%', mx: 'auto', mt: 5 }}>
            {art.length > 0 ? (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'space-around' }}>
                {art.map((item) => (
                  <Card key={item.id} sx={{ width: '45%', maxWidth: 300, backgroundColor: '#ffa7a7' }}>
                    {item.isAudio ? (
                      <Box sx={{ height: 140, display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#262626' }}>
                        <IconButton onClick={() => handlePlayPause(item.id)}>
                          {playingAudio === item.id ? <PauseIcon /> : <PlayArrowIcon />}
                        </IconButton>
                        <audio 
                          ref={el => audioRef.current[item.id] = el} 
                          src={item.audio} 
                          onEnded={() => setPlayingAudio(null)}
                        />
                      </Box>
                    ) : (
                      <CardMedia component="img" height="140" image={item.image} alt={item.title} />
                    )}
                    <CardContent>
                      <Typography variant="body1">{item.title}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item.description}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            ) : (
              <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center' }}>
                No Art found.
              </Typography>
            )}

            <Box sx={{ mt: 15, textAlign: 'center', color: 'white' }}>
              {!walletAddress ? (
                <>
                  <Typography variant="h6" color="white">
                    Connect Your Wallet
                  </Typography>
                  <Typography variant="body1" color="gray">
                    Connect your crypto wallet to import your NFTs.
                  </Typography>
                  <Button
                    onClick={handleConnectWallet}
                    variant="contained"
                    sx={{
                      mt: 2,
                      bgcolor: 'linear-gradient(to right, #fc466b, #3f5efb)',
                      color: 'white',
                      width: '100%',
                    }}
                  >
                    Connect Wallet
                  </Button>
                </>
              ) : ""}
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default Gems;