import React, { useState, useEffect } from 'react';
import { Box, Typography, CardMedia, Modal, Button } from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import placeHolderImage from '../assets/bjork.jpeg';
import GetLast6Digits from './GetLastDigits';
import { Link } from 'react-router-dom';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import ImageIcon from '@mui/icons-material/Image';
import FlagIcon from '@mui/icons-material/Flag';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase'; // Adjust import path as needed
import { BigNumber } from 'ethers';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  centerMode: true,
  slidesToScroll: 4,
  arrows:false,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const GemCarousel = ({ selectedGem, distanceAway }) => {
  const [address, setAddress] = useState('');
  const [nftOwner, setNftOwner] = useState(null);

  const getAddressFromLatLng = async () => {
    const { latitude, longitude } = selectedGem;
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCAMU56Mr8co3Nyrvo7pHYMw10xWTAehuM`);
    const data = await response.json();
    setAddress(data.results[0]?.formatted_address || 'Address not found');
  };

  useEffect(() => {
    if (selectedGem.latitude && address === '') {
      getAddressFromLatLng()
    }
    if (selectedGem.owner && nftOwner === null) {
      fetchNFTOwner();
    }
  }, [selectedGem]);

  const fetchNFTOwner = async () => {
    if (selectedGem.owner && selectedGem.tokenId) {
      try {
        // Constructing the query
        const tokenIdStr = BigNumber.from(selectedGem.tokenId).toString();

        const q = query(
          collection(db, 'Inventory'),
          where('ownerAddress', '==', selectedGem.owner),
          where('tokenId', '==', tokenIdStr),
          where('contractAddress', '==', "0xf14507959deA7272d7A97F9B5D700b7E9D48EF1F")
        );
  
        // Fetching the data
        const querySnapshot = await getDocs(q);
        console.log('Fetching NFT owner...',querySnapshot);
        if (!querySnapshot.empty) {
          // Assuming you want the first matching document
          const data = querySnapshot.docs[0].data();
          setNftOwner(data);
        } else {
          console.log('No matching documents.');
        }
      } catch (error) {
        console.error('Error fetching NFT owner:', error);
      }
    }
  };

  return (
    <Box sx={{ width: '100%', marginTop: '30px' }}>
      <Slider  {...settings}>
        <Box sx={{ textAlign: 'center', p: 2, height: "200px", width: "150px !important", backgroundColor:"#181818", boxShadow: "#000 0px 3px 13px 2px"}}>
          <Typography variant="h6" style={{marginBottom: "10px"}}>owner</Typography>
          <Link to={`/viewprofile/${nftOwner? nftOwner.userId : selectedGem.userId}`} style={{ textDecoration: 'none' }}>
            <CardMedia
                component="img"
                sx={{
                  width: 80,
                  height: 80,
                  borderRadius: '50%',
                  margin: '20px auto',
                }}
                image={selectedGem.userImage ? selectedGem.userImage : placeHolderImage}
                alt={selectedGem.userName}
              />
          </Link>
          {selectedGem.owner ? 
            <Typography style={{ p: 2}} variant="body1"><a href={'https://testnet.snowtrace.io/address/'+selectedGem.owner}>{GetLast6Digits(selectedGem.owner)}</a></Typography>:
            <Typography variant="body2">{selectedGem.userName}</Typography>
          }
        </Box>
        <Box sx={{ textAlign: 'center', p: 2, height: "200px", width: "150px !important", backgroundColor:"#181818", boxShadow: "#000 0px 3px 13px 2px"}}>
          <Typography variant="h6" style={{marginBottom: "10px"}}>Distance</Typography>
          <AutoAwesomeMotionIcon />
          <Typography style={{ marginTop: "15px"}} variant="body1">{distanceAway(selectedGem.latitude, selectedGem.longitude)}</Typography>
        </Box>
        <Box sx={{ textAlign: 'center', p: 2, height: "200px", width: "150px !important", backgroundColor:"#181818", boxShadow: "#000 0px 3px 13px 2px"}}>
          <Typography variant="h6" style={{marginBottom: "10px"}}>Location</Typography>
          <AssistantDirectionIcon />
          <Typography style={{ marginTop: "10px"}} variant="body1">{address}</Typography>
        </Box>
        <Box sx={{ textAlign: 'center', p: 2, height: "200px", width: "150px !important", backgroundColor:"#181818", boxShadow: "#000 0px 3px 13px 2px"}}>
          <Typography variant="h6" style={{marginBottom: "50px"}}>Art Type</Typography>
          <Typography style={{ marginTop: "10px"}} variant="body1">{selectedGem.isAudio ? <AudiotrackIcon /> : <ImageIcon />}</Typography>
        </Box>
        <Box sx={{ textAlign: 'center', p: 2, height: "200px", width: "150px !important", backgroundColor:"#181818", boxShadow: "#000 0px 3px 13px 2px"}}>
          <Typography variant="h6" style={{marginBottom: "10px"}}>On Chain</Typography>
          <AutoAwesomeMotionIcon />
          {selectedGem.onChain ? 
            <Box>
              <Typography style={{ p: 2, marginTop: "10px"}} variant="body1">on fuji</Typography>
              <Box>
              <Typography style={{ p: 2, marginTop: "10px"}} variant="body1">can only be collected</Typography>
              <Typography style={{ p: 2, marginTop: "10px"}} variant="body1">with a smart wallet</Typography>
            </Box>
            </Box>
            :
            <Typography style={{ p: 2, marginTop: "10px"}} variant="body1">nope</Typography>
          }
        </Box>
      </Slider>
    </Box>
  );
};

export default GemCarousel;