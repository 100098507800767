import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, IconButton, Modal, Button, CardMedia } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import GemCarousel from './GemCarousel';
import LoadingSpinner from "./LoadingSpinner";

const SelectedGem = ({ user, selectedGem, isCollectModalOpen, handleCloseCollectModal, toggleExpand, distanceAway, canCollect, collect, gemIsLoading }) => {

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    // Reset audio state when selectedGem changes
    setIsPlaying(false);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [selectedGem]);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const isAudio = selectedGem && selectedGem.audio;

  return (
    <Modal open={isCollectModalOpen} onClose={handleCloseCollectModal}>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          bgcolor: '#1c1c1c',
          color: 'white',
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          textAlign: 'center',
          height: '80vh',
          overflowY: 'auto',
          animation: 'slideUp 0.3s ease-in-out',
          '&::-webkit-scrollbar': { display: 'none' },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none'
        }}
      >
        {selectedGem && (
          <Box
          sx={{
            height: 400
          }}
          >
            {selectedGem.isHidden ? (
              <Box
                sx={{
                  height: 300,
                  width: '100%',
                  maxWidth: "500px",
                  margin: '0 auto',
                  bgcolor: '#1a1a1a', // Dark square color
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="body1" color="gray">
                  you can only view this piece if ur near it.
                </Typography>

              </Box>
            ) : (
              isAudio ? (
                <Box sx={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#262626', maxWidth: "500px", margin: '0 auto' }}>
                  <IconButton onClick={handlePlayPause} sx={{ fontSize: 60 }}>
                    {isPlaying ? <PauseIcon fontSize="inherit" /> : <PlayArrowIcon fontSize="inherit" />}
                  </IconButton>
                  <audio 
                    ref={audioRef}
                    src={selectedGem.audio}
                    onEnded={() => setIsPlaying(false)}
                  />
                </Box>
              ) : (
                <CardMedia
                  component="img"
                  onClick={toggleExpand}
                  height="200"
                  image={selectedGem.image}
                  alt={selectedGem.title}
                  style={{ width:'100%', height: 'auto', cursor: 'pointer', borderRadius: 2, marginBottom: 2, maxWidth: "500px", margin: '0 auto' }}
                />
              )
            )}
            <Typography variant="h3" style={{ fontFamily: 'wood', letterSpacing:2 , margin: '25px'}} gutterBottom>{selectedGem.title}</Typography>
            <Typography style={{ textAlign: 'left', margin: '0 auto', width: '80%', fontFamily: 'monospace' }} variant="body1" paragraph>{selectedGem.description}</Typography>

            <GemCarousel selectedGem={selectedGem} distanceAway={distanceAway} />

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, m: 3 }}>
              <Button
                variant="contained"
                onClick={() => collect(selectedGem.tokenId)}
                disabled={!canCollect(user.uid, selectedGem.userId, selectedGem.latitude, selectedGem.longitude, selectedGem.owner)}
              >
                Collect
              </Button>
              <Button
                variant="outlined"
                onClick={handleCloseCollectModal}
              >
                Close
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default SelectedGem;