import React, { useState, useContext, useEffect } from 'react';
import { Box, CardMedia, Typography, Button, Tab, Tabs, TextField, Modal, Slide } from '@mui/material';
import { UserContext } from '../context/UserProvider';
import { db, storage } from '../config/firebase'; // Assuming storage is configured in firebase.js
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { ethers } from 'ethers';
import NFTS from './NFTS';
import Create from './Create'
import  Art from './Art'

const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 43114, 43113] // Mainnet, Ropsten, Rinkeby, Goerli, Kovan, Avalanche Mainnet, Fuji Testnet
});


const AddObject = ({ open, handleClose, handleOpen }) => {
  const { user } = useContext(UserContext);
  const [tabIndex, setTabIndex] = useState(0);
  const [walletAddress, setWalletAddress] = useState('');
  const { activate, library, account } = useWeb3React();
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  useEffect(() => {
    if (user) {
      const checkWallet = async () => {
        const userDoc = doc(db, 'Users', user.uid);
        const userDocSnapshot = await getDoc(userDoc);
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          if (userData.walletAddress) {
            setWalletAddress(userData.walletAddress);
          }
        }
      };
      checkWallet();
    }
  }, [user]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setFilePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleConnectWallet = async () => {
    try {
      await activate(injected);
      if (library) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        setWalletAddress(address);

        const userDoc = doc(db, 'Users', user.uid);
        await updateDoc(userDoc, { walletAddress: address });
      }
    } catch (error) {
      console.error('Error connecting with MetaMask:', error);
    }
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Box
          sx={{
            bottom: 0,
            transform: 'translateX(-50%)',
            width: '100%',
            height: '100%',
            background: "#000",
            marginTop: "15%"
          }}
        >
        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab sx={{ color: 'white' }} label="Create" />
          <Tab sx={{ color: 'white' }} label="NFTs" />
          <Tab sx={{ color: 'white' }} label="Art" /> {/* New Tab for Art */}
        </Tabs>

          <Box sx={{ width: '100%' }}>
            {tabIndex === 0 && <Create user={user} />}
            {tabIndex === 1 && (
              <Box sx={{ mt: 15, textAlign: 'center', color: 'white' }}>
                {!walletAddress ? (
                  <>
                    <Typography variant="h6" color="white">
                      Connect Your Wallet
                    </Typography>
                    <Typography variant="body1" color="gray">
                      Connect your crypto wallet to import your NFTs.
                    </Typography>
                    <Button
                      onClick={handleConnectWallet}
                      variant="contained"
                      sx={{
                        mt: 2,
                        bgcolor: 'linear-gradient(to right, #fc466b, #3f5efb)',
                        color: 'white',
                        width: '100%',
                      }}
                    >
                      Connect Wallet
                    </Button>
                  </>
                ) : (
                  <NFTS />
                )}
              </Box>
            )}
            {tabIndex === 2 && <Art />} {/* Render Art Component */}
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default AddObject;