import React, {useContext} from 'react';
import { Button, Typography, Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import { auth, googleAuthProvider, db } from '../config/firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { signInWithPopup, getAuth, signInWithCustomToken } from 'firebase/auth';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { ethers } from 'ethers';
import { UserContext } from '../context/UserProvider';

const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42]
});

const Landing = () => {

  const navigate = useNavigate();
  const { activate, library } = useWeb3React();
  const { updateUserContext } = useContext(UserContext);

  const handleGoogleSignIn = async () => {
    try {
      const { user } = await signInWithPopup(auth, googleAuthProvider);

      const userDocRef = doc(db, 'Users', user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (!userDocSnapshot.exists()) {
        await setDoc(userDocRef, {
          email: user.email,
        });
      } else {
        const userData = userDocSnapshot.data();
        updateUserContext({ ...user, ...userData });
      }

      navigate('/');
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  const handleMetaMaskLogin = async () => {
    console.log("handleMetaMaskLogin");
    try {
      await activate(injected);
      if (library) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const userAddress = await signer.getAddress();
        console.log("userAddress", userAddress);

        if (userAddress) {
          console.log("userAddress is valid:", userAddress);

          const response = await fetch('https://us-central1-hidden-gems-f8933.cloudfunctions.net/generateCustomToken', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userAddress }),
          });

          const data = await response.json();
          console.log("data received from backend:", data);

          if (data.token) {
            const { token } = data;
            console.log("token received:", token);
            const auth = getAuth();
            const userCredential = await signInWithCustomToken(auth, token);
            const user = userCredential.user;

            const userDocRef = doc(db, 'Users', user.uid);
            const userDocSnapshot = await getDoc(userDocRef);

            if (!userDocSnapshot.exists()) {
              await setDoc(userDocRef, {
                address: userAddress,
              });
            }
            const userData = userDocSnapshot.data();
            updateUserContext({ ...user, ...userData });
            navigate('/');
          } else {
            console.error("No token received from backend");
          }
        } else {
          console.error("Invalid userAddress:", userAddress);
        }
      }
    } catch (error) {
      console.error('Error connecting with MetaMask:', error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#000',
        color: '#fff',
      }}
    >
      <Container sx={{ textAlign: 'center' }}>
        <Typography variant="h1" sx={{ fontWeight: 'bold', mb: 5 }}>
          HIDDEN
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 7 }}>
          places
        </Typography>

        <Container sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <Button
            variant="contained"
            startIcon={<GoogleIcon />}
            onClick={handleGoogleSignIn}
            sx={{
              mb: 2,
              width: '80%',
              maxWidth: '300px',
              backgroundColor: '#fff',
              color: '#000',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#e0e0e0',
              },
            }}
          >
            Sign in with Google
          </Button>

          <Button
            variant="contained"
            onClick={handleMetaMaskLogin}
            sx={{
              mb: 2,
              width: '80%',
              maxWidth: '300px',
              backgroundColor: '#fff',
              color: '#000',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#e0e0e0',
              },
            }}
          >
            Connect with MetaMask
          </Button>

        </Container>

        <Typography variant="body2" sx={{ pt: 7, maxWidth: '300px', margin: '0 auto' }}>
          By clicking this button you agree to the{' '}
          <a href="/terms" style={{ color: '#fff', textDecoration: 'underline' }}>
            Terms of Service
          </a>{' '}
          and{' '}
          <a href="/privacy" style={{ color: '#fff', textDecoration: 'underline' }}>
            Privacy Policy
          </a>
        </Typography>
      </Container>
    </Box>
  );
};

export default Landing;
