// src/components/PrivateRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../context/UserProvider';
import LoadingSpinner from './LoadingSpinner';

const PrivateRoute = ({ children }) => {
  const { user, loading } = useContext(UserContext);

  console.log("PrivateRoute - Loading:", loading); // Debugging line
  console.log("PrivateRoute - User:", user); // Debugging line

  if (loading) {
    return <LoadingSpinner height="100vh" />; // Or a loading spinner
  }

  return user ? children : <Navigate to="/landing" />;
};

export default PrivateRoute;
