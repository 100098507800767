import React, { useState, useContext, useEffect } from 'react';
import { Box, Modal, Typography, TextField, Button } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../config/firebase'; // Ensure you have your firebaseConfig set up correctly
import { UserContext } from '../context/UserProvider';

const EditProfile = ({ handleClose }) => {
  const { user, updateUserContext } = useContext(UserContext);
  const [username, setUsername] = useState(user?.username || '');
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setFilePreview(null);
    }
  }, [selectedFile]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpdate = async () => {
    if (user) {
      const userDoc = doc(db, 'Users', user.uid);

      let profileImageUrl = user.profileImage || '';
      if (selectedFile) {
        const fileRef = ref(storage, `profileImages/${user.uid}/${selectedFile.name}`);
        await uploadBytes(fileRef, selectedFile);
        profileImageUrl = await getDownloadURL(fileRef);
      }

      await updateDoc(userDoc, { username, profileImage: profileImageUrl });

      // Update user context
      const updatedUser = { ...user, username, profileImage: profileImageUrl };
      updateUserContext(updatedUser);

      handleClose(); // Assuming this function closes the modal or performs some UI update
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "100%",
        height: "100%",
        bgcolor: '#006987',
        color: 'white',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '60%', maxWidth: 700, mx: 'auto', textAlign: 'center' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Edit Profile
        </Typography>

        {filePreview && (
          <Box sx={{ mb: 2 }}>
            <img src={filePreview} alt="Profile Preview" style={{ width: '200px', height: '200px',  }} />
          </Box>
        )}

        <TextField
          label="Username"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{ mb: 2, color: 'white !important' }}
        />
        <Button
          variant="contained"
          component="label"
          sx={{ mb: 2, backgroundColor: '#000000', color: '#006987', ":hover": { backgroundColor: '#000000', color: '#ffffff' } }}
        >
          Upload Profile Image
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={handleFileChange}
          />
        </Button>
        <Button
          sx={{ backgroundColor: '#000000', color: '#006987', ":hover": { backgroundColor: '#000000', color: '#ffffff' } }}
          variant="contained"
          fullWidth
          onClick={handleUpdate}
          disabled={!username || !selectedFile}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default EditProfile;