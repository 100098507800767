// src/components/Profile.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, IconButton, Button, Modal, Slide } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportIcon from '@mui/icons-material/Support';
import LogoutIcon from '@mui/icons-material/Logout';
import EditProfile from '../components/EditProfile'; 
import AddObject from '../components/AddObject'; // Import the AddObject component
import ConnectWallet from '../components/ConnectWallet';
import { auth } from '../config/firebase';
import { UserContext } from '../context/UserProvider';
import placeHolderImage from '../assets/bjork.jpeg';
import Gems from '../components/Gems';

const Profile = () => {
  const navigate = useNavigate();
  const [addObjectOpen, setAddObjectOpen] = useState(false);
  const [gemsPinnedOpen, setGemsPinnedOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [connectWalletOpen, setConnectWalletOpen] = useState(false);
  const { user, logout } = useContext(UserContext);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddObjectOpen = () => setAddObjectOpen(true);
  const handleAddObjectClose = () => setAddObjectOpen(false);
  const handleGemsOpen = () => setGemsPinnedOpen(true);
  const handleGemsClose = () => setGemsPinnedOpen(false);


  const handleConnectWalletOpen = () => {
    setConnectWalletOpen(true);
  };

  const handleConnectWalletClose = () => {
    setConnectWalletOpen(false);
  };

  return (
    <Box sx={{ 
        bgcolor: 'black', 
        color: 'white', 
        p: 2
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "45px" }}>
      <IconButton onClick={() => navigate(-1)} sx={{ color: 'white' }}>
          <ArrowBackIcon />
        </IconButton>
 
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
            <Box
              sx={{
                width: 100,
                height: 100,
                borderRadius: '50%',
                overflow: 'hidden',
                mb: 2,
                backgroundColor: user.profileImage ? 'transparent' : 'black',
              }}
            >
              <img
                src={user.profileImage ? user.profileImage : placeHolderImage}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
            <Typography variant="h4">{user.username ? user.username : "add username"}</Typography>
            <IconButton onClick={handleOpen} sx={{ color: 'white' }}>
              <EditIcon />
            </IconButton>
          </Box>
          <Typography variant="body1" sx={{ color: 'gray' }}>
            edit
          </Typography>
        </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
        <Button
          onClick={handleGemsOpen}
          sx={{
            bgcolor: '#00885f',
            color: 'white',
            borderRadius: 2,
            width: '45%',
            height: 165,
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <AccountBalanceWalletIcon fontSize="large" />
            <Typography>gems</Typography>
            {/* <Typography variant="h5">0</Typography> */}
          </Box>
        </Button>

        <Button
          onClick={handleAddObjectOpen}
          sx={{
            bgcolor: '#960100',
            color: 'white',
            borderRadius: 2,
            width: '45%',
            height: 165,
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <AccountBalanceWalletIcon fontSize="large" />
            <Typography>inventory</Typography>
            {/* <Typography variant="h5">0</Typography> */}
          </Box>
        </Button>
      </Box>

      <Box sx={{ mt: 3, px: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="body1">Balance</Typography>
          <Typography variant="h6">???</Typography>
        </Box>

        <Button
          onClick={() => navigate('/')}
          startIcon={<SettingsIcon />}
          fullWidth
          sx={{
            justifyContent: 'flex-start',
            color: 'white',
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            mb: 1,
            p: 1.5,
          }}
        >
          Settings
        </Button>

        <Button
          onClick={() => navigate('/')}
          startIcon={<SupportIcon />}
          fullWidth
          sx={{
            justifyContent: 'flex-start',
            color: 'white',
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            mb: 1,
            p: 1.5,
          }}
        >
          Support
        </Button>

        <Button
          onClick={logout}
          startIcon={<LogoutIcon />}
          fullWidth
          sx={{
            justifyContent: 'flex-start',
            color: 'white',
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            mb: 1,
            p: 1.5,
          }}
        >
          Log out
        </Button>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Slide direction="up" in={open} mountOnEnter unmountOnExit>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              transform: 'translateX(-50,-50%)',
              width: '100%',
              boxShadow: 24,
              borderRadius: 2,
              height: '77%',
            }}
          >
            <EditProfile handleClose={handleClose} />
          </Box>
        </Slide>
      </Modal>

      <Gems open={gemsPinnedOpen} handleOpen={handleConnectWalletOpen} handleClose={handleGemsClose} />
      <AddObject open={addObjectOpen} handleOpen={handleConnectWalletOpen} handleClose={handleAddObjectClose} />

      <Modal open={connectWalletOpen} onClose={handleConnectWalletClose}>
        <ConnectWallet onClose={handleConnectWalletClose} />
      </Modal>

    </Box>
  );
};

export default Profile;
