import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDbkp9s28GpIVa12YqC4GdTrphxVr4SoJ0",
  authDomain: "hidden-gems-f8933.firebaseapp.com",
  projectId: "hidden-gems-f8933",
  storageBucket: "hidden-gems-f8933.appspot.com",
  messagingSenderId: "114954258179",
  appId: "1:114954258179:web:e526c62205bbc7b8d94b36",
  measurementId: "G-ME7NTB1M34"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const googleAuthProvider = new GoogleAuthProvider();
const appleAuthProvider = new OAuthProvider('apple.com');

setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Persistence set to local");
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

export { auth, db, storage, googleAuthProvider, appleAuthProvider };