import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { Button, Typography } from '@mui/material';

// Avalanche Mainnet and Fuji Testnet Chain IDs
const injected = new InjectedConnector({
  supportedChainIds: [43114, 43113] // Avalanche Mainnet, Fuji Testnet
});

const WalletStatus = ({contentAlign, color}) => {
  console.log("WalletStatus - color:", color);
  const { activate, active, account, library, deactivate, chainId } = useWeb3React();
  const [walletAddress, setWalletAddress] = useState('');
  const [networkName, setNetworkName] = useState('');

  useEffect(() => {
    if (active && account) {
      setWalletAddress(account);
      setNetworkName(chainId === 43114 ? 'Avalanche Mainnet' : chainId === 43113 ? 'Fuji Testnet' : 'Unknown Network');
    } else {
      setWalletAddress('');
      setNetworkName('');
    }
  }, [active, account, chainId]);

  const handleConnectWallet = async () => {
    try {
      await activate(injected);
    } catch (error) {
      console.error('Error connecting with MetaMask:', error);
    }
  };

  const handleDisconnectWallet = () => {
    try {
      deactivate();
      setWalletAddress('');
      setNetworkName('');
    } catch (error) {
      console.error('Error disconnecting wallet:', error);
    }
  };

  return (
    <div style={{textAlign: contentAlign? contentAlign : 'center'}}>
      {active ? (
        <div>
          <Typography variant="h6">Wallet is connected to {networkName}</Typography>
          <Typography variant="body1">Address: {walletAddress}</Typography>
          <Button variant="contained" onClick={handleDisconnectWallet}>
            Disconnect Wallet
          </Button>
        </div>
      ) : (
        <div>
          <Typography variant="h6">connect wallet</Typography>
          <Button style={{backgroundColor: color? color : 'blue', textAlign: contentAlign? contentAlign : 'center'}} variant="contained" onClick={handleConnectWallet}>
            Connect Wallet
          </Button>
        </div>
      )}
    </div>
  );
};

export default WalletStatus;
