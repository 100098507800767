// src/components/ConnectWallet.js
import React, { useEffect, useContext } from 'react';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from '../context/UserProvider';
import { db } from '../config/firebase';
import { doc, updateDoc } from 'firebase/firestore';

const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 43114, 43113] // Mainnet, Ropsten, Rinkeby, Goerli, Kovan, Avalanche Mainnet, Fuji Testnet
});

const ConnectWallet = ({ onClose }) => {
  const { activate, account, library, chainId, active, deactivate } = useWeb3React();
  const { user, updateUserContext } = useContext(UserContext);

  useEffect(() => {
    if (active && account) {
      const updateWalletAddress = async () => {
        const userDocRef = doc(db, 'Users', user.uid);
        await updateDoc(userDocRef, { walletAddress: account });
        updateUserContext({ walletAddress: account });
        console.log("Account:", account);
        console.log("ChainId:", chainId);
        console.log("Library:", library);
      };
      updateWalletAddress();
    }
  }, [active, account, chainId, library, user, updateUserContext]);

  const handleConnect = () => {
    activate(injected);
  };

  const handleDisconnect = async () => {
    try {
      deactivate();
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <Box sx={{ bgcolor: 'black', color: 'white', height: '100%', p: 3, textAlign: 'center', position: 'relative', margin: 'auto' }}>
      <IconButton
        onClick={onClose}
        sx={{ position: 'absolute', top: 16, left: 16, color: 'white' }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h4" sx={{ mb: 5, mt: 15 }}>
        Show your NFTs in the real world
      </Typography>
      <Box sx={{ textAlign: 'left', mb: 5, mt: 10 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          1. Connect your wallet
        </Typography>
        <Typography variant="body1" sx={{ mb: 5, color: 'gray' }}>
          Connect your crypto wallet and log in with MetaMask, Rainbow, Zerion, Safe
        </Typography>
        <Typography variant="h6" sx={{ mb: 2 }}>
          2. Import NFTs into AR
        </Typography>
        <Typography variant="body1" sx={{ mb: 10, color: 'gray' }}>
          Import your favorite NFTs and place them in augmented reality in your slots
        </Typography>
      </Box>
      <Button
        variant="contained"
        onClick={handleConnect}
        sx={{
          bgcolor: 'limegreen',
          color: 'black',
          width: '80%',
          height: 50,
          fontSize: '1rem',
          textTransform: 'none',
          mb: 5,
        }}
      >
        Connect wallet
      </Button>
      <Box sx={{ mt: 5 }}>
        <Typography variant="caption" display="block" sx={{ color: 'gray', mb: 1 }}>
          Privacy policy
        </Typography>
        <Typography variant="caption" display="block" sx={{ color: 'gray', mb: 1 }}>
          NFTs Policy
        </Typography>
        <Typography variant="caption" display="block" sx={{ color: 'gray' }}>
          Terms of Use
        </Typography>
      </Box>
    </Box>
  );
};

export default ConnectWallet;
