import React, {useContext, useEffect, useState, useRef } from 'react';
import { Box, Typography, Avatar, IconButton, Card, CardContent, CardMedia } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase'; // Update the path to your Firebase config
import LoadingSpinner from '../components/LoadingSpinner'; // Import your LoadingSpinner component
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserProvider';

const ViewProfile = () => {
  const { userId } = useParams();
  const { user } = useContext(UserContext);
  const [userData, setUserData] = useState(null);
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [playingAudio, setPlayingAudio] = useState(null);
  const navigate = useNavigate();
  const audioRef = useRef({});

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Fetch user data
        const userDoc = await getDoc(doc(db, 'Users', userId));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }

        // Fetch inventory data
        const inventoryQuery = query(collection(db, 'Inventory'), 
          where('userId', '==', userId),
          where('onChain', '==', false)
        );
        const inventorySnapshot = await getDocs(inventoryQuery);

        // Get all inventory IDs
        const inventoryIds = inventorySnapshot.docs.map(doc => doc.id);

        // Query the Gems collection for pinned items
        const gemsQuery = query(collection(db, 'Gems'),
          where('inventoryId', 'in', inventoryIds)
        );
        const gemsSnapshot = await getDocs(gemsQuery);

        // Create a Set of pinned inventory IDs for faster lookup
        const pinnedIds = new Set(gemsSnapshot.docs.map(doc => doc.data().inventoryId));

        // Process inventory items and add isPinned property
        const fetchedInventory = inventorySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            isPinned: pinnedIds.has(doc.id),
            isAudio: data.audio ? true : false
          };
        });

        setInventory(fetchedInventory);
      } catch (error) {
        console.error('Error fetching user data or inventory:', error);
      } finally {
        setLoading(false);
      }
    };

    if (userData === null) {
      fetchUserData();
    }
    
  }, [userId, userData]);

  const handlePlayPause = (artId) => {
    if (playingAudio === artId) {
      audioRef.current[artId].pause();
      setPlayingAudio(null);
    } else {
      if (playingAudio) {
        audioRef.current[playingAudio].pause();
      }
      audioRef.current[artId].play();
      setPlayingAudio(artId);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 3,
        bgcolor: '#000',
        borderRadius: 2,
        boxShadow: 1,
      }}
    >
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', marginBottom: "45px" }}>
        <IconButton onClick={() => navigate(-1)} sx={{ color: 'white' }}>
          <ArrowBackIcon />
        </IconButton>
      </Box>
      {userData && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'white',
            mb: 3,
          }}
        >
          <Avatar
            src={userData.profileImage}
            alt={userData.username}
            sx={{ width: 100, height: 100, mb: 2 }}
          />
          <Typography variant="h5">{userData.username}</Typography>
        </Box>
      )}
     <Box sx={{ width: '100%' }}>
        <Typography variant="h6" gutterBottom>
          Inventory
        </Typography>
        {inventory.length > 0 ? (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'space-around' }}>
            {inventory.map((art) => (
              <Card key={art.id} sx={{ width: '45%', maxWidth: 300 }}>
                {art.isHidden && art.userId != user.uid ? (
                  <Box sx={{ height: 140, display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#262626' }}>
                    <VisibilityOffIcon sx={{ fontSize: 40, color: 'grey' }} />
                  </Box>
                ) : art.isAudio ? (
                  <Box sx={{ height: 140, display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#262626' }}>
                    <IconButton onClick={(e) => {
                      e.stopPropagation();
                      handlePlayPause(art.id);
                    }}>
                      {playingAudio === art.id ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                    <audio 
                      ref={el => audioRef.current[art.id] = el} 
                      src={art.audio} 
                      onEnded={() => setPlayingAudio(null)}
                    />
                  </Box>
                ) : (
                  <CardMedia component="img" height="140" image={art.image} alt={art.title} />
                )}
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1">{art.title}</Typography>
                    {art.isPinned && <LocationOnIcon color="primary" />}
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {art.description}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        ) : (
          <Typography variant="body1">Empty</Typography>
        )}
      </Box>
    </Box>
  );
};

export default ViewProfile;